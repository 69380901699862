import {
    SET_KEYVAL
  } from '@/core/services/store/common.module';
import store from '@/core/services/store';

export function setKeyValue(key, value) {
    let keys = store.getters['keyvals'];

    if (keys === undefined || keys === null || !keys || keys === 'undefined') {
        keys = {};
    }

    if (typeof keys === 'string' || keys instanceof String) {
        keys = {};
    }

    keys[key] = value;

    store.dispatch(SET_KEYVAL, keys);
}

export function getKeyValue(key, ifNullValue) {
    const keys = store.getters['keyvals'];

    if (typeof keys === 'string' || keys instanceof String) {
        // repair
        keys = {};
        store.dispatch(SET_KEYVAL, keys);
        return ifNullValue;
    }

    if (keys === undefined || keys === null || !keys || keys === 'undefined') {
        return ifNullValue;
    }

    if (key in keys) {
        return keys[key];
    }

    return ifNullValue;
}