<template>
  <div>
    <div class="d-flex justify-content-between px-6">
      <div class="d-flex flex-column">
        <div v-if="member && member.firstname">
          <span class="text-secondary mb-2">{{ $t('SHOP_ORDER.FOR_MEMBER') }}</span>
          <span>{{ member.firstname + ' ' + member.lastname }}</span>
          <span>{{ member.address }}</span>
          <span>{{ member.zipcode }} {{ member.post }}</span>
        </div>
      </div>
      <div class="d-flex flex-column">
        <span class="text-secondary text-right mb-2">{{ $t('SHOP_ORDER.DATE') }}</span>
        <span>{{ getDate() }}</span>
      </div>
    </div>
    <div class="bg-secondary my-12">
      <div class="d-flex justify-content-between px-6 pt-6 pb-2">
        <h4>{{ $t('SHOP_ORDER.PRODUCT') }}</h4>
        <h4>{{ $t('SHOP_ORDER.AMOUNT') }}</h4>
      </div>
      <div
        v-for="(product, index) in products"
        :key="index"
        class="d-flex justify-content-between px-6"
      >
        <span
          >{{ product.quantity > 1 ? `${product.quantity}x&nbsp;&nbsp;` : '' }}{{ product.shop_item.name }}</span
        >
        <span>{{ formatCurrency(product.price_sek) }} </span>
      </div>
      <div class="mx-6 mt-6" :style="{ borderTop: '2px solid #888' }" />
      <div class="d-flex justify-content-between p-6">
        <span>{{ $t('SHOP_ORDER.TOTAL_AMOUNT') }}</span>
        <span>{{ formatCurrency(order.amount) }} </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2">
      
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { mapGetters } from 'vuex';

let formatter = null;

export default {
  name: 'pending-payment-info',
  data() {
    return {
      logo: '',
      member_id: null
    };
  },
  props: ['member','products','order'],
  computed: {
    ...mapGetters(['isMemAuthenticated'])
  },
  methods: {
    getDate() {
      return dayjs().format('YYYYMMDD');
    },
    formatCurrency(value) {
      if (!formatter) {
        formatter = new Intl.NumberFormat('sv', {
          style: 'currency',
          currency: this.order.currency,
          minimumFractionDigits: 2
        });
      }
      
      return formatter.format(value);
    }
  },
  mounted() {
    formatter = new Intl.NumberFormat('sv', {
      style: 'currency',
      currency: this.order.currency,
      minimumFractionDigits: 2
    });


    this.company_id = this.order.company_id;

    axios
      .get(`/company/public/${this.company_id}`)
      .then(res => {
        if (res.status === 200 && res.data.logo) {
          this.logo = res.data.logo;
        } else {
          this.logo = '/assets/images/memlist-logo-svart.png';
        }
      })
      .catch(() => {
        this.logo = '/assets/images/memlist-logo-svart.png';
      });

  }
};
</script>

<style></style>
