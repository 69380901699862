<template>
  <div class="d-flex flex-column align-items-center">

    <PaymentStatusComponent 
      v-if="order"
      :order="order"
    />

    <p style="margin-top: 128px;"></p>

  </div>
</template>

<script>
import axios from 'axios';
import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';
import SelectPaymentComponent from '@/view/pages/members/payment/SelectPaymentComponent.vue';
import SwishPaymentComponent from '@/view/pages/members/payment/SwishPaymentComponent.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {
  name: 'ReturnAllPaymentsPage',
  props: [],
  mixins: [ toasts ],
  components: {
    PaymentStatusComponent,
    SwishPaymentComponent,
    SelectPaymentComponent
  },
  computed: {

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },
    
  },
  data() {
    return {
      selected_payment_method: null,
      shop_order_id: null,
      token: null,
      order: null
    };
  },
  async mounted() {
    this.shop_order_id = this.$route.params.shop_order_id;
    this.token = this.$route.params.token;

    this.order = await this.get_order(this.token, this.shop_order_id);
  },
  watch: {},
  methods: {
    async get_order(token, shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/token/${token}/${shop_order_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_order error', err);
      }

      throw `unable to get order`;
    },
    
  }
};
</script>


<style lang="css" scoped>
.amount {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  
  text-align: center;
}

.info {
  color: #1a1a1d;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

</style>